import JsonFetcher from '../components/layout/JsonFetcher';

export default class Index {
  constructor() {
    if (document.querySelector('.js-json-fetcher')) {
      this.initJsonFetcher();
    }
  }

  initJsonFetcher() {
    const jsonFetcher = new JsonFetcher(document.querySelector('.js-json-fetcher'));
    jsonFetcher.init();
  }
}
