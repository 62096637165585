export default function () {
  const ccInputs = document.querySelectorAll('.js-toggle-cc-form');

  if (ccInputs) {
    ccInputs.forEach((input) => {
      input.addEventListener('click', () => {
        const allFormies = document.querySelectorAll('[class*="js-form-"]');
        allFormies.forEach((f) => f.classList.add('hide'));

        const formie = document.querySelector(`.js-form-${input.dataset.formId}`);
        formie.classList.remove('hide');
      });
    });
  }
}
