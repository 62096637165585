/* eslint-disable */
import videojs from "video.js";
import 'videojs-youtube';

import $ from "jquery";
import {isMobile} from '../../utils/isMobile';

export default function () {

  let autoplay = true;
  let controls = true;

  if (isMobile) {
    autoplay = false;
    controls = true;
  }

  const videojsOptionsDefault = {
    preload: 'auto',
    responsive: true,
    fluid: true,
    controls: true,
    youtube: {"iv_load_policy": 1},
  };
  const videojsOptionsHeader = JSON.parse(JSON.stringify(videojsOptionsDefault));
  videojsOptionsHeader.autoplay = true;
  videojsOptionsHeader.controls = false;
  videojsOptionsHeader.responsive = true;
  videojsOptionsHeader.loop = true;
  videojsOptionsHeader.muted = true;

//hoeft niet te autoplayen
  $.each($('.video-js--header'), function (index, videoPlayerEl) {
    const videojsOptionsHeaderCustom = JSON.parse(JSON.stringify(videojsOptionsHeader));
    if($(videoPlayerEl).attr('poster')) {
      videojsOptionsHeaderCustom.poster = $(videoPlayerEl).attr('poster');
    }
    console.log(videojsOptionsHeaderCustom);

    const player = videojs(videoPlayerEl, videojsOptionsHeaderCustom, function onPlayerReady() {
      console.log('ready - header');
      setTimeout(() => {
        if(videoPlayerEl.currentTime === 0 && videoPlayerEl.paused === true && videoPlayerEl.ended === false && videoPlayerEl.readyState > 2) {
          player.play();
        }
      }, 1000);
      setTimeout(() => {
        if(videoPlayerEl.currentTime === 0 && videoPlayerEl.paused === true && videoPlayerEl.ended === false && videoPlayerEl.readyState > 2) {
          player.play();
        }
      }, 2000);
    });
  });

  $.each($('.video-js--manual'), function (index, videoPlayerEl) {
    //player in window element, zodat het aanspreekbaar is bij openen modal
    const playerId = videoPlayerEl.getAttribute('data-playerid');
    window[playerId] = videojs(videoPlayerEl, videojsOptionsDefault, function onPlayerReady() {
      console.log('ready - player');
    });
  });
}
