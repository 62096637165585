import $ from 'jquery';

import 'classlist-polyfill';
import 'sticky-kit/dist/sticky-kit';
import 'picturefill';

import objectFitImages from 'object-fit-images';
import navigation from './components/layout/navigation';
import trackingEvents from './components/layout/trackingEvents';
import swiper from './components/layout/swiper-overview';
import responsiveVideos from './components/layout/responsiveVideos';
import videoJS from './components/layout/videoJS';
import validation from './components/forms/validation';
import floatingLabels from './components/forms/floatingLabels';
import ccForm from './components/forms/ccForm';
import gatedContent from './components/forms/gatedContent';
import ModalManager from './components/modals/ModalManager';
import SlideAccordion from './components/accordion/SlideAccordion';
import Appointment from './components/appointment';

import Home from './pages/Home';
import Contact from './pages/Contact';
// import Locations from './pages/Locations';
import About from './pages/About';
import Vacency from './pages/Vacency';
import Components from './pages/Components';
// require('jquery-touchswipe/jquery.touchSwipe'); // use with fancybox, cycle2, etc
import popups from './components/layout/popups';

require('./utils/nativeConsole');
// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
navigation();
responsiveVideos();
trackingEvents();
floatingLabels();
ccForm();
objectFitImages();
Appointment();

$('.js-sticky-sidebar').stick_in_parent({
  offset_top: 40,
});

// eslint-disable-next-line
window.modalManager = new ModalManager();
popups();

// Forms
validation();
gatedContent();

[...document.querySelectorAll('.js-file-upload')].forEach((fileUploadWrap) => {
  const fileInputLabel = fileUploadWrap.querySelector('.js-file-label span');
  const fileInputEl = fileUploadWrap.querySelector('.js-file-input');
  fileInputEl.addEventListener('change', (e) => {
    const fileName = e.target.files[0].name;
    fileInputLabel.innerHTML = fileName;
  });
});

videoJS();
swiper();
trackingEvents();

[...document.querySelectorAll('.js-slide-accordion')].forEach((accordionEl) => {
  const accordion = new SlideAccordion(accordionEl); // eslint-disable-line
});

[...document.querySelectorAll('.floating-label-container')].forEach((inputCon) => {
  if (inputCon.querySelectorAll('input').length > 0) {
    [...inputCon.querySelectorAll('input')].forEach((i, key) => {
      if (i.placeholder === '') {
        // eslint-disable-next-line no-param-reassign
        i.placeholder = inputCon.querySelectorAll('label')[key].textContent.trim();
      }
    });
  }
});

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Home,
  Contact,
  About,
  Vacency,
  // Locations,
  Components,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    new pages[pageClassName](); // eslint-disable-line no-new
  }
}

window.modalManager.checkModalOnPageLoad();
