/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { form } from '../forms/form';

export default function () {
  let currentStep = 1;
  let countLocations = 0;
  const stepState = {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  };
  let productLinkedLocations = [];
  let productLinkedLocationsEmails = [];

  const getAllSteps = document.querySelectorAll('.js-appointment');

  if (getAllSteps.length > 0) {
    const getAllChangeBtns = document.querySelectorAll('.js-button-change');

    const init = () => {
      const getCurrentStep = document.querySelector(`.js-appointment[data-step="${currentStep}"]`);
      const getCurrentStepOptions = getCurrentStep.querySelectorAll('input');
      const getCurrentStepInput = document.querySelector(`input[name="message[${getCurrentStep.dataset.stepId}]"]`);
      const getSubjectInput = document.querySelector('input[name="subject"]');
      const getCurrentStepSubmit = getCurrentStep.querySelector('[type="button"]');
      const getCurrentChosen = getCurrentStep.querySelector('.js-chosen-option');
      const getCurrentContent = getCurrentStep.querySelector('.js-appointment-content');
      const getCurrentChangeBtn = getCurrentStep.querySelector('.js-button-change');

      const addLinkedLocationsToArray = (option) => {
        productLinkedLocations = option.parentElement.dataset.linkedLocations.split(', ');
        productLinkedLocationsEmails = option.parentElement.dataset.linkedLocationsEmails.split(', ');
      };

      const hideLocations = () => {
        const getAllLocations = getCurrentStep.querySelectorAll('div[data-location-id]');
        countLocations = 0;

        getAllLocations.forEach((location) => {
          location.style.display = 'none';

          if (productLinkedLocations.includes(location.dataset.locationId)) {
            location.style.display = 'block';
            const getEmailID = productLinkedLocationsEmails.find((email) => email.includes(location.dataset.locationId));
            location.setAttribute('data-email', getEmailID.split(`${location.dataset.locationId} - `)[1]);
            countLocations += 1;
          }
        });
      };

      const setCurrentStepStyling = () => {
        getCurrentStep.style.display = 'block';
        getCurrentStep.style.padding = '40px 20px 60px';

        if (getCurrentContent) {
          getCurrentContent.style.height = '100%';
          getCurrentContent.style.opacity = '1';
          getCurrentContent.style.pointerEvents = 'all';
        }

        if (getCurrentChangeBtn) {
          getCurrentChangeBtn.style.display = 'none';
          getCurrentChangeBtn.style.pointerEvents = 'none';
        }

        if (getCurrentChosen) {
          getCurrentChosen.innerHTML = '';
        }
      };

      const setPreviousStepStyling = () => {
        getCurrentContent.style.height = '0px';
        getCurrentContent.style.opacity = '0';
        getCurrentContent.style.pointerEvents = 'none';

        getCurrentChangeBtn.style.display = 'block';
        getCurrentChangeBtn.style.pointerEvents = 'all';

        getCurrentStep.style.padding = '40px 20px';

        getCurrentChosen.innerHTML = `${window.translations && window.translations.forms && window.translations.forms.chosenPreset ? window.translations.forms.chosenPreset : 'Gekozen optie(s):'} <strong>${stepState[currentStep]}</strong>`;
      };

      const resetFutureSteps = () => {
        for (let i = (currentStep + 1); i < (getAllSteps.length + 1); i += 1) {
          const step = document.querySelector(`.js-appointment[data-step="${i}"]`);

          if (i === 2 && stepState[1] !== '') {
            const getDescription = step.querySelector('.header-matrix__rte');
            const replaceChoiceProduct = getDescription.innerHTML.replace(stepState[1], 'CHOICE_PRODUCT');
            const finalText = replaceChoiceProduct.replace(countLocations, 'COUNT_LOCATIONS');
            getDescription.innerHTML = finalText;
          }

          if (i !== 6 && i !== 5) {
            const getStepSubmit = step.querySelector('button');
            if (getStepSubmit) {
              getStepSubmit.setAttribute('disabled', 'disabled');
              getStepSubmit.style.pointerEvents = 'none';
            }
          }

          const getStepOptions = step.querySelectorAll('input');
          // eslint-disable-next-line no-return-assign
          getStepOptions.forEach((option) => option.checked = false);

          step.style.display = 'none';
          stepState[i] = '';
        }
      };

      const disableButton = () => {
        if (getCurrentStepSubmit) {
          getCurrentStepSubmit.setAttribute('disabled', 'disabled');
          getCurrentStepSubmit.style.pointerEvents = 'none';
        }
      };

      const enableButton = () => {
        if (getCurrentStepSubmit) {
          getCurrentStepSubmit.removeAttribute('disabled');
          getCurrentStepSubmit.style.pointerEvents = 'all';
        }
      };

      const setSelectedOptionsMultipleChoice = () => {
        let dayOptions = '';
        for (let y = 0; y < getCurrentStepOptions.length; y += 1) {
          if (getCurrentStepOptions[y].checked) {
            dayOptions = `${dayOptions !== '' ? `${dayOptions} / ` : ''}${getCurrentStepOptions[y].value}`;
          }
        }

        if (getCurrentStepInput.value && dayOptions !== '') {
          getSubjectInput.value = getSubjectInput.value.replace(getCurrentStepInput.value, dayOptions);
        } else if (dayOptions === '' && getCurrentStepInput.value) {
          getSubjectInput.value = getSubjectInput.value.replace(getCurrentStepInput.value, `[${getCurrentStep.dataset.stepId}]`);
        } else {
          getSubjectInput.value = getSubjectInput.value.replace(`[${getCurrentStep.dataset.stepId}]`, dayOptions);
        }

        stepState[currentStep] = dayOptions;
        getCurrentStepInput.value = dayOptions;

        if (dayOptions !== '' && getCurrentStepSubmit) {
          enableButton();
        }
      };

      const setSelectedOption = (value) => {
        stepState[currentStep] = value;
        getCurrentStepInput.value = value;
        getSubjectInput.value = getSubjectInput.value.replace(`[${getCurrentStep.dataset.stepId}]`, value);
      };

      const setContactEmail = (email) => {
        const getToEmailInput = document.querySelector('input[name="toEmail"]');
        getToEmailInput.value = email;
      };

      const submitStep = () => {
        if (stepState[currentStep] !== '') {
          setPreviousStepStyling();

          currentStep += 1;
          init();
        }
      };

      resetFutureSteps();
      setCurrentStepStyling();

      if (currentStep === 2) {
        hideLocations();

        const getDescription = getCurrentStep.querySelector('.header-matrix__rte');
        const replaceChoiceProduct = getDescription.innerHTML.replace('CHOICE_PRODUCT', stepState[1]);
        const finalText = replaceChoiceProduct.replace('COUNT_LOCATIONS', countLocations);
        getDescription.innerHTML = finalText;
      }

      if (currentStep !== 5) {
        for (let i = 0; i < getCurrentStepOptions.length; i += 1) {
          const option = getCurrentStepOptions[i];

          // eslint-disable-next-line no-loop-func
          option.addEventListener('click', () => {
            disableButton();

            if (option.type === 'checkbox') {
              setSelectedOptionsMultipleChoice();
            } else if (option.checked) {
              if (currentStep === 1) {
                addLinkedLocationsToArray(option);
              }

              setSelectedOption(option.value);

              if (currentStep === 2) {
                getCurrentStepInput.value = `${option.value} - ${option.dataset.locationAddress}`;
                setContactEmail(option.parentElement.dataset.email);
              }

              submitStep();
            }
          });
        }
      }

      if (getCurrentStepSubmit) {
        getCurrentStepSubmit.addEventListener('click', (e) => {
          e.preventDefault();

          if (stepState[currentStep] !== '') {
            setPreviousStepStyling();

            currentStep += 1;
            init();
          }
        });
      }
    };

    if (getAllChangeBtns) {
      getAllChangeBtns.forEach((btn) => {
        btn.addEventListener('click', (e) => {
          e.preventDefault();
          currentStep = parseInt(btn.dataset.stepId, 10);

          init();
        });
      });
    }

    init();
    form('#form-appointment');
  }
}
