import $ from 'jquery';

export default function () {
  if (document.createElement('input').placeholder === undefined) {
    $('.floating-label-container input, .floating-label-container textarea').addClass('active');
    $('.floating-label-container label').addClass('active');
  } else {
    $('.floating-label-container').each((index, element) => {
      $(element).find('input, textarea').on('select keydown', (e) => {
        const $target = $(e.currentTarget);
        $target.parent().find('label').addClass('active');
        $target.addClass('active');
      });
      $(element).find('input, textarea').on('keyup blur ', (e) => {
        const $target = $(e.currentTarget);
        if ($target.val().length === 0) {
          $target.parent().find('label').removeClass('active');
          $target.removeClass('active');
        }
      });
    });
  }
}
