import { EventEmitter } from 'events';

export default class AccordionItem extends EventEmitter {
  constructor(el) {
    super();
    this.element = el;
    this.isOpen = false;
    this.isOpening = false;
    this.isClosing = false;
    this.contentElement = this.element.querySelector('.js-accordion-content');
    this.toggleElement = this.element.querySelector('.js-accordion-toggle');
    this.registerEventListeners();
  }

  registerEventListeners() {
    this.toggleElement.addEventListener('click', () => this.toggle());

    this.toggleElement.addEventListener('keydown', (e) => {
      const keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        this.toggle();
      }
    });
  }

  toggle() {
    if (!this.isOpening && !this.isClosing) {
      this.emit('toggle', this);
    }
  }
}
