import Swiper from 'swiper';

export default class About {
  constructor() {
    const $swiperEl = document.querySelector('#history-swiper');
    if ($swiperEl) {
      const swiperElSlides = $swiperEl.querySelectorAll('.swiper-slide');
      if (swiperElSlides.length > 1) {
        const swiperPlans = new Swiper($swiperEl, {
          speed: 600,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        const elements = document.querySelectorAll('.history-swiper-navpart__navitem');
        Array.prototype.forEach.call(elements, (el) => {
          el.addEventListener('click',
            (e) => {
              const index = e.currentTarget.getAttribute('data-slide-index');
              e.preventDefault();
              swiperPlans.slideTo(index);
            });
        });
      }
    }
  }
}
