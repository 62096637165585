import Accordion from './Accordion';

export default class SlideAccordion extends Accordion {
  constructor(element) {
    super(element);
    this.init();
  }

  openAccordionItem(accordionItem) {
    // eslint-disable-next-line no-param-reassign
    accordionItem.isOpening = true;
    const { element, contentElement } = accordionItem;
    element.dataset.open = 'true';
    contentElement.style.height = 'auto';
    const height = `${contentElement.clientHeight}px`;
    contentElement.style.height = '0px';

    setTimeout(() => {
      contentElement.style.height = height;

      setTimeout(() => {
        contentElement.style.height = '';
        // eslint-disable-next-line no-param-reassign
        accordionItem.isOpen = true;
        // eslint-disable-next-line no-param-reassign
        accordionItem.isOpening = false;
      }, 250);
    }, 0);
  }

  closeAccordionItem(accordionItem) {
    // eslint-disable-next-line no-param-reassign
    accordionItem.isClosing = true;
    const { element, contentElement } = accordionItem;
    contentElement.style.height = `${contentElement.clientHeight}px`;

    contentElement.addEventListener('transitioncancel', () => {
      contentElement.style.height = '';
    }, {
      once: true,
    });

    setTimeout(() => {
      contentElement.style.height = '0px';

      setTimeout(() => {
        element.dataset.open = 'false';
        contentElement.style.height = '';
        // eslint-disable-next-line no-param-reassign
        accordionItem.isOpen = false;
        // eslint-disable-next-line no-param-reassign
        accordionItem.isClosing = false;
      }, 250);
    }, 0);
  }

  init() {
    this.on('item_toggled', (accordionItem) => {
      if (!accordionItem.isClosing && !accordionItem.isOpening) {
        if (!accordionItem.isOpen) {
          this.openAccordionItem(accordionItem);
        } else {
          this.closeAccordionItem(accordionItem);
        }
      }
    });
  }
}
