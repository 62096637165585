import Swiper from 'swiper';

export default function () {
  // eslint-disable-next-line
  [...document.querySelectorAll('.swiper-overview')].forEach((el) => {
    const swiperEl = el;
    if (swiperEl) {
      const swiperElSlides = swiperEl.querySelectorAll('.swiper-slide');
      if (swiperElSlides.length > 1) {
        // eslint-disable-next-line
        const swiperOverview = new Swiper(swiperEl, {
          speed: 600,
          slidesPerView: 'auto',
          // slidesPerGroup: 'auto',
          spaceBetween: 0,
          watchOverflow: true,
          // centerMode: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      }
    }
  });
}
