import { EventEmitter } from 'events';
import AccordionItem from './AccordionItem';

export default class Accordion extends EventEmitter {
  constructor(el, itemElementQuery = '.js-accordion-item') {
    super();
    this.element = el;
    this.itemElementQuery = itemElementQuery;
    this.items = [];
    this.registerItems();
  }

  registerItems() {
    const items = this.element.querySelectorAll(this.itemElementQuery);
    this.items = [...items].map((el) => {
      const item = new AccordionItem(el);
      item.on('toggle', (accordionItem) => {
        this.emit('item_toggled', accordionItem);
      });

      return item;
    });
  }
}
