import cookie from 'cookiejs';

export default function () {
  const popupTriggers = document.getElementsByClassName(
    'js-modal-trigger-popup',
  );
  if (popupTriggers.length) {
  // we can only show one popup, so ignore the others
    const trigger = popupTriggers[0];

    const popupId = trigger.getAttribute('data-modal-id');
    const showOnce = trigger.getAttribute('data-show-once');
    if (showOnce) {
      if (!cookie.get(`cookie-${popupId}`)) {
        trigger.click();
        cookie.set(`cookie-${popupId}`, '1', 365);
      }
    } else {
      trigger.click();
    }
  }
}
