/* eslint-disable no-mixed-operators */
function getScrollPosition() {
  const scrollPosition = window.pageYOffset
    || document.documentElement.scrollTop || document.body.scrollTop;
  return scrollPosition;
}

export default function () {
  const header = document.querySelector('.page-header--fixed');
  let lastScrollTop = 0;

  const handleScroll = () => {
    const scrollPos = getScrollPosition();

    if (scrollPos >= lastScrollTop) {
      header.classList.add('page-header--collapsed');
    } else if (scrollPos < 50) {
      header.classList.remove('page-header--collapsed');
    }

    lastScrollTop = scrollPos;
  };

  window.addEventListener('scroll', handleScroll);
}
