import $ from 'jquery';

export default function () {
  $('.js-button--track').on('click', (e) => {
    const $btn = $(e.currentTarget);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'button',
      buttonTitle: $btn.data('title'),
      buttonLabel: $btn.data('label'),
    });
  });

  $('.js-button--track-download').on('click', (e) => {
    const $btn = $(e.currentTarget);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'download',
      downloadTitle: $btn.data('title'),
      downloadLabel: $btn.data('label'),
    });
  });
}
