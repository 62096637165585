import { form } from './form';

export default () => {
  const gatedContentForms = document.querySelectorAll('.js-gated-content-form');

  const downloadFile = (url) => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', url.lastIndexOf('/') + 1);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  [...gatedContentForms].forEach((gatedContentForm) => {
    const input = gatedContentForm.querySelector('[name="message[url]"]');
    form(gatedContentForm, {
      generalSuccessMessage: window.translations.forms.successDownload,
      propagateGtm: true,
      replaceFormOnSuccess: true,
      successCallback: () => {
        if (input && input.value !== '') {
          downloadFile(input.value);
        }
      },
    });
  });
};
